import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import { Modal } from "ant-design-vue";

let bulletinfOnTwoPages = false;
const totalPtsTreshold = 18.5;
let studentCount = 1;

function isNumber(value) {
  return !isNaN(Number(value));
}
const waitLoadingImage = (image) => {
  return new Promise((res, rej) => {
    image.onload = (t) => {
      res();
    };
    image.onerror = (e) => {
      rej();
    };
  });
};

const updateWithSpecDataOne = (data, moyenne, minMoyenne, maxMoyenne) => {
  data.moyenne = moyenne;
  data.minMoyenne = minMoyenne;
  data.maxMoyenne = maxMoyenne;
  return data;
};

const updateWithSpecDataMany = (peda, spec) => {
  peda.minMoyenne = spec.minMoyenne;
  peda.maxMoyenne = spec.maxMoyenne;
  const specStudents = spec.students;
  peda.students = peda.students.map((pedaStudent) => {
    const specStudent = specStudents.find((s) => s.id == pedaStudent.id);
    if (specStudent) {
      pedaStudent.moyenne = specStudent.moyenne;
      pedaStudent.minMoyenne = specStudent.minMoyenne;
      pedaStudent.maxMoyenne = specStudent.maxMoyenne;
    }
    return pedaStudent;
  });
  return peda;
};

const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
  const cornerRadius = {
    upperLeft: 0,
    upperRight: 0,
    lowerLeft: 0,
    lowerRight: 0,
  };
  if (typeof stroke == "undefined") {
    stroke = true;
  }
  if (typeof radius === "object") {
    for (const side in radius) {
      cornerRadius[side] = radius[side];
    }
  }

  ctx.lineWidth = 1;
  ctx.strokeStyle = "rgb(70, 187, 239)";

  ctx.beginPath();
  ctx.moveTo(x + cornerRadius.upperLeft, y);
  ctx.lineTo(x + width - cornerRadius.upperRight, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
  ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - cornerRadius.lowerRight,
    y + height
  );
  ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
  ctx.lineTo(x, y + cornerRadius.upperLeft);
  ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
  ctx.closePath();
  if (stroke) {
    ctx.stroke();
  }
  if (fill) {
    ctx.fill();
  }
};
const getCertificationNameByMoyenne = function (moyenne, certifs) {
  for (const cert of certifs)
    if (
      Number(moyenne) >= Number(cert.min) &&
      Number(moyenne) <= Number(cert.max)
    ) {
      return cert.name;
    }

  return "";
};
const drawModuleHeader = (
  doc,
  ctx,
  startY,
  title,
  style = 1,
  isLangModule = false
) => {
  // module title

  let x = style == 0 ? 80 : 65;
  let y = 63 + startY;
  let width = style == 0 ? 110 : 125;
  doc.setFillColor(64, 187, 239);
  roundRect(ctx, x, y, width, 8, { upperRight: 8 }, true, true);
  doc.setFillColor(64, 187, 239);
  doc.setDrawColor(0xff, 0xff, 0xff);
  doc.rect(x, y + 20, width + 10, 10, "FD");

  // module header shapes
  x = 158;
  y = 72.6 + startY;
  width = 45;
  doc.setFillColor(64, 187, 239);
  roundRect(ctx, x, y, width, 7.5, { upperRight: 7.5 }, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  x = 148;
  y = 72.6 + startY;
  width = style == 0 ? 26 : 18;
  if (isLangModule) {
    width -= style == 0 ? 10 : 4;
    x += style == 0 ? 13 : 4;
  }
  doc.setFillColor(224, 237, 243);
  //العدد / 20
  roundRect(ctx, x, y, width, 7.4, { upperRight: 7.4 }, true, true);
  // doc.setFillColor(0xff, 0xff, 0xff);
  // doc.setDrawColor(0xff, 0xff, 0xff);
  // doc.rect(x, y + 9.9, width + 10, 10, "FD");

  doc.setLineWidth(0.4);
  doc.setFillColor(224, 237, 243);
  doc.setDrawColor(64, 187, 239);
  // معدل
  if (isLangModule) {
    doc.rect(137, 72.3 + startY, 15, 8, "FD");
  }

  //معدل المجال;
  if (style == 1) {
    x = 0;
    if (isLangModule) {
      x = 10;
    }
    doc.rect(134 - x, 72.3 + startY, 15, 8, "FD");
  } else {
    if (isLangModule) {
      doc.rect(149, 72.3 + startY, 12, 8, "FD");
    }
  }
  //توصيّات المدّرس
  x = 0;
  width =
    style == 0 && !isLangModule ? 46 : style == 0 && isLangModule ? 40 : 38;
  if (isLangModule) {
    width -= 2;
    x = 8;
  }
  doc.rect(style == 0 ? 111 : 96 - x, 72.3 + startY, width, 8, "FD");

  // max / min
  width = 15;
  x = 0;
  if (isLangModule && style == 1) {
    width = 11;
    x = 4;
  }
  doc.rect(style == 0 ? 80 : 65, 72.3 + startY, width, 8, "FD");
  doc.rect((style == 0 ? 95 : 80) - x, 72.3 + startY, width, 8, "FD");

  doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

  // header module texts
  if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
  else doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = title;
  doc.setTextColor(0xff, 0xff, 0xff);
  width = doc.getTextWidth(text);
  doc.text(text, 138 - width / 2 - (style == 0 ? 0 : 15), 69 + startY);

  doc.setFont("Amiri", "Bold");
  text = "المادة";
  width = doc.getTextWidth(text);
  doc.text(text, 179, 78 + startY);

  text = "العدد / 20";
  doc.setFontSize(9);
  x = style == 0 ? 158 : 151;
  if (isLangModule) {
    doc.setFontSize(7);
    x += 4;
  }

  doc.setTextColor(62, 187, 239);
  width = doc.getTextWidth(text);
  doc.text(text, x, 78 + startY);

  if (style == 1) {
    if (isLangModule) {
      text = "معدل";
      doc.setFontSize(9);
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, 146, 77.5 + startY, "center");
    }
    text = "معدل\nالمجال";
    x = 0;
    if (isLangModule) x = 11;
    doc.setFontSize(9);
    doc.setTextColor(64, 187, 239);
    width = doc.getTextWidth(text);
    doc.text(text, 142 - x, 75.2 + startY, "center");
  } else {
    if (isLangModule) {
      text = "معدل";
      x = 0;
      doc.setFontSize(9);
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, 155, 77 + startY, "center");
    }
  }

  text = "توصيّات المدّرس";
  x = 0;

  if (isLangModule) x = 8;
  doc.setFontSize(11);
  doc.setTextColor(64, 187, 239);
  width = doc.getTextWidth(text);
  doc.text(text, style == 0 ? 125 - x : 104 - x, 77.5 + startY);

  doc.setFontSize(8);
  x = 0;
  if (isLangModule) {
    doc.setFontSize(7);
    x = 5.5;
  }
  text = "أعلى\nعدد بالقسم";
  doc.setTextColor(64, 187, 239);
  width = doc.getTextWidth(text);
  doc.text(text, style == 0 ? 103 : 87 - x, 76 + startY, "center");

  if (isLangModule) x = 1;

  text = "أدنى\nعدد بالقسم";
  doc.setTextColor(64, 187, 239);
  width = doc.getTextWidth(text);
  doc.text(text, style == 0 ? 87 : 71 - x, 76 + startY, "center");
};

const drawModuleFooter = (doc, ctx, startY, avg) => {
  let x = 158;
  let y = startY;
  let width = 35.5;
  doc.setFillColor(16, 169, 229);
  doc.setDrawColor(16, 169, 229);
  doc.rect(x, y, width + 10, 8.5, "FD");

  x = 111;
  y = startY + 0.4;
  width = 45.5;
  doc.setFillColor(255, 255, 255);

  roundRect(ctx, x, y, width, 7.8, { lowerLeft: 7.8 }, true, true);

  // footer text (moyenne)
  doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(13);
  let text = "معدل المجال";
  doc.setTextColor(0xff, 0xff, 0xff);
  doc.text(text, 169, startY + 5.5);

  doc.setFont("Amiri", "Bold");
  doc.setFontSize(14);
  text = String(avg);
  doc.setTextColor(67, 62, 63);
  width = doc.getTextWidth(text);
  doc.text(text, 134.75 - width / 2, startY + 5.5);
};

const drawTrimesterMoyenneAndCards = async (
  doc,
  ctx,
  startY,
  moy,
  max,
  min,
  style = 1,
  stampImage,
  adminAppreciation,
  config
) => {
  const { selectedLevel, schoolDetails, bulletinDate, certifs } = config;

  const value = style == 1 ? 5 : 0;
  const currentPageInfo = doc.getCurrentPageInfo();
  if (bulletinfOnTwoPages) doc.setPage(currentPageInfo.pageNumber - 1);

  doc.setFillColor(46, 142, 178);
  doc.setDrawColor(46, 142, 178);
  roundRect(
    ctx,
    45 - value * 2,
    82.2 + startY,
    33.5 - value,
    8.2,
    { upperRight: 7.8 },
    true,
    false
  );

  doc.setFont("Noto Sans Arabic", "Bold");
  doc.setFontSize(11);
  let text = "معدل الثلاثي";
  doc.setTextColor(0xff, 0xff, 0xff);
  doc.text(text, 51 - (value * 2 + value / 2), 87.5 + startY);

  doc.setLineWidth(0.2);
  doc.setFillColor(224, 237, 243);
  doc.setDrawColor(64, 187, 239);

  roundRect(
    ctx,
    6,
    82.2 + startY,
    38 - value * 2,
    7.8,
    { upperRight: 7.8 },
    true,
    true
  );
  roundRect(
    ctx,
    6,
    82.2 + startY,
    19 - value,
    7.8,
    { upperRight: 7.8 },
    true,
    true
  );

  doc.setFont("Amiri", "Bold");
  doc.setFontSize(7);
  doc.setTextColor(64, 187, 239);
  doc.text(
    "أعلى\nمعدل بالقسم",
    38 - (value + value / 2),
    85.5 + startY,
    "right"
  );
  doc.text("أدنى\nمعدل بالقسم", 19.2 - value / 2, 85.5 + startY, "right");

  // moyenne rect
  doc.setFillColor(224, 237, 243);
  doc.setDrawColor(64, 187, 239);
  doc.setLineWidth(0.15);
  doc.rect(45 - value * 2, 91.5 + startY, 33.5 - value, 16.4, "FD");

  doc.setFillColor(225, 225, 226);
  doc.rect(6, 91.5 + startY, 19 - value, 16.4, "FD");
  doc.rect(25 - value, 91.5 + startY, 19 - value, 16.4, "FD");

  // moyenne text
  doc.setTextColor(67, 62, 63);
  doc.setFontSize(16);
  text = String(moy);
  let width = doc.getTextWidth(text);
  doc.text(text, 63 - width / 2 - (value * 2 + value / 2), 100.5 + startY);

  doc.setFontSize(13);
  text = String(max);
  width = doc.getTextWidth(text);
  doc.text(text, 34.75 - width / 2 - (value + value / 2), 100.5 + startY);

  text = String(min);
  width = doc.getTextWidth(text);
  doc.text(text, 15.25 - width / 2 - value / 2, 100.5 + startY);

  doc.setFillColor(224, 237, 243);
  doc.setDrawColor(64, 187, 239);

  doc.rect(6, 115 + startY, 72.5 - value * 3, 30);
  doc.rect(8, 111 + startY, 67.5 - value * 3, 8, "FD");

  doc.rect(6, 153 + startY, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, 149 + startY, 36.75, 8, "FD");

  doc.rect(6, 190 + startY, 72.5 - value * 3, 55);
  doc.rect(40.75 - value * 3, 186 + startY, 36.75, 8, "FD");

  doc.rect(6, 252 + startY, 72.5 - value * 3, 30);
  doc.rect(40.75 - value * 3, 248 + startY, 36.75, 8, "FD");

  doc.setFontSize(11 - value / 2);
  doc.setTextColor(64, 187, 239);
  text = "ملاحظات المدّرس)ة( حول السّلوك و المواظبة";
  doc.text(text, 12, 116 + startY);

  doc.setTextColor(67, 62, 63);
  doc.setFontSize(10);
  const tt = adminAppreciation;
  doc.text(String(tt), 35, 125 + startY, {
    maxWidth: /[a-zA-Z]/.test(tt) ? 50 : 60,
    align: "center",
  });

  doc.setFontSize(13);
  text = "الشّهادة";
  doc.text(text, 54 - value * 3, 154 + startY);
  doc.setTextColor(67, 62, 63);
  doc.setFontSize(17);

  doc.text(
    getCertificationNameByMoyenne(moy, certifs),
    35 - value * 3,
    170 + startY
  );
  doc.setFontSize(13);

  doc.setTextColor(64, 187, 239);
  text = selectedLevel > 9 ? "مدير)ة( المعهد" : "مدير)ة( المدرسة";

  doc.text(text, 45 - value * 3, 191 + startY);

  doc.setTextColor(67, 62, 63);
  doc.text(
    schoolDetails.headMaster + "\n" + moment(bulletinDate).format("YYYY/MM/DD"),
    30 - value * 3,
    202 + startY
  );

  //stamp

  if (config.showStamp && stampImage && stampImage.src) {
    try {
      doc.addImage(stampImage, "PNG", 10, 190, 40, 40);
    } catch (e) {}
  }
  //end stamp
  doc.setTextColor(64, 187, 239);
  doc.setFontSize(16);

  text = "إمضاء الولي";
  doc.text(text, 48 - value * 3, 253 + startY);
};

const componentToHex = function (c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = function (r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

//generate bulletin pdf
const drawBulletin = async (
  doc,
  ctx,
  student,
  bulletinData,
  affichageBulletin,
  stampImage,
  config
) => {
  const { selectedLevel, schoolarYearName, selectedTrimester, schoolDetails } =
    config;
  const style = selectedLevel > 2 ? 1 : 0;

  const pdf_width = doc.internal.pageSize.width;
  let width = 0;
  let width2 = 0;

  // background header
  const img = new Image();
  img.src = "./resources/images/bg1.png";
  doc.addImage(img, "png", 0, 0, pdf_width, 40 - (style == 1 ? 12 : 0));
  // Header texts
  doc.setFont("PT Bold Heading", "Bold");
  doc.setFontSize(13);
  let text = "المندوبيّة الجهويّة للتربيّة";
  doc.setTextColor(99, 87, 91);
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 15, 12);
  text = schoolDetails.delegeRegional
    ? "بـ" + schoolDetails.delegeRegional
    : ".....................................بـ";
  width = doc.getTextWidth(text);
  doc.text(text, pdf_width - width - 15, 22);

  doc.setFont("Amiri", "normal");

  doc.setTextColor(67, 62, 63);
  doc.setFontSize(12);
  let schoolTypeText =
    selectedLevel <= 9 ? " المدرسة الابتدائيّة " : "  المعهد الخاص ";
  text = schoolDetails.ArabicName
    ? schoolDetails.ArabicName
    : "..............................";
  let schoolNameWidth = doc.getTextWidth(schoolDetails.ArabicName);
  width = doc.getTextWidth(text);
  doc.text(schoolTypeText, 105 - width - 15, 12);
  doc.text(text, 105 - width - 17 - schoolNameWidth, 12);

  text = ` ${schoolarYearName}  : السنة الدراسيّة`;
  width = doc.getTextWidth(text);
  doc.text(text, 105 - width - 15, 20);

  // ending header line
  doc.setDrawColor(52, 172, 215);
  doc.setLineWidth(0.8);
  doc.line(
    0,
    40 - (style == 1 ? 12 : 0),
    pdf_width,
    40 - (style == 1 ? 12 : 0)
  );

  // trimester rounded rect title
  doc.setDrawColor(52, 172, 215);
  doc.setLineWidth(0.4);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(
    pdf_width / 2 - 25,
    34 - (style == 1 ? 12 : 0),
    50,
    12,
    4,
    4,
    "FD"
  );

  // trimester text
  doc.setFontSize(16);
  doc.setFont("Amiri", "normal");
  doc.setTextColor(52, 172, 215);
  let title = "";
  switch (selectedTrimester) {
    case "1":
      title = "الثلاثي الأول";
      break;
    case "2":
      title = "الثلاثي الثاني";
      break;
    case "3":
      title = "الثلاثي الثالث";
      break;
  }
  doc.setFont("Noto Sans Arabic", "Bold");
  width = doc.getTextWidth(title);
  doc.text(title, pdf_width / 2 - width / 2, 41.2 - (style == 1 ? 12 : 0));

  const className = bulletinData.classroomName;
  let xCoord = pdf_width - 8;
  function renderText(
    doc,
    text,
    x,
    y,
    font = "normal",
    fontSize = 12,
    color = [99, 87, 91]
  ) {
    doc.setFont("Amiri", font);
    doc.setFontSize(fontSize);
    doc.setTextColor(...color);
    const width = doc.getTextWidth(text);
    doc.text(text, x - width, y);
    return width;
  }

  const yCoord = 53 - (style == 1 ? 12.5 : 0);
  let gap = 5;
  let studentLabel = student.gender === "male" ? " : التلميذ" : " : التلميذة";
  let studentLabelWidth = renderText(doc, studentLabel, xCoord, yCoord);
  xCoord -= studentLabelWidth;
  let fullNameWidth = renderText(
    doc,
    student.fullName,
    xCoord,
    yCoord,
    "Bold",
    13
  );
  xCoord -= fullNameWidth + gap;
  let classLabelWidth = renderText(doc, " : القسم", xCoord, yCoord);
  xCoord -= classLabelWidth;
  let classWidth = renderText(doc, className, xCoord, yCoord);
  xCoord -= classWidth + gap;

  if (affichageBulletin.identUnique && student.uniqueId) {
    let uniqueIdLabelWidth = renderText(
      doc,
      " : المعرف الوحيد",
      xCoord,
      yCoord
    );
    xCoord -= uniqueIdLabelWidth;
    let uniqueIdWidth = renderText(
      doc,
      String(student.uniqueId),
      xCoord,
      yCoord
    );
    xCoord -= uniqueIdWidth + gap;
  } else {
    xCoord -= gap;
  }

  if (affichageBulletin.numberStudentInsc) {
    let numStudentsLabelWidth = renderText(
      doc,
      " : عدد التلاميذ المرسّمين",
      xCoord,
      yCoord
    );
    xCoord -= numStudentsLabelWidth;
    let numberOfStudents = String(
      Array.isArray(bulletinData.students)
        ? bulletinData.students.length
        : bulletinData.students
    );
    let numStudentsWidth = renderText(doc, numberOfStudents, xCoord, yCoord);
    xCoord -= numStudentsWidth + gap;
  }

  // Add date of birth
  const lineHeight = 8;
  const rightMargin = pdf_width - 8;
  let currentY = yCoord;
  let birthDateLabel = " : تاريخ الولادة";
  let birthDate = student.BirthDate
    ? moment(student.BirthDate).format("DD/MM/YYYY")
    : "";
  const requiredWidth =
    doc.getTextWidth(birthDateLabel) + doc.getTextWidth(birthDate) + gap;
  // Check if we need to move to next line based on remaining space
  if (xCoord < requiredWidth) {
    currentY += lineHeight;
    xCoord = rightMargin;
  }

  let birthDateLabelWidth = renderText(doc, birthDateLabel, xCoord, currentY);
  xCoord -= birthDateLabelWidth;
  let birthDateWidth = renderText(doc, birthDate, xCoord, currentY);
  xCoord -= birthDateWidth + gap;

  if (xCoord < 50) {
    gap = 15;
    xCoord -= gap;
  }

  const cellToDelete = {};
  let sumCoef = 0;
  let topMargin =
    currentY > yCoord ? 5 - (style == 1 ? 13 : 0) : -4 - (style == 1 ? 13 : 0);

  let tableSize = 9;
  switch (selectedLevel) {
    case 1:
      tableSize = 12;
      break;
    case 2:
      tableSize = 12;
      break;
    case 3:
      tableSize = 11;
      break;
    case 4:
      tableSize = 10.5;
      break;
    default:
      break;
  }
  let totatPts = student.modules.length * 2;
  student.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.subSubjectMarks) totatPts += c.subSubjectMarks.length * 0.75;
      else totatPts++;
    });
  });
  if (totatPts > totalPtsTreshold) tableSize *= 0.9;

  student.modules.forEach((m) => {
    let arr = [];
    let array = [];
    sumCoef += m.coef;

    drawModuleHeader(doc, ctx, topMargin, m.name, style, m.submodules);
    topMargin += 17.2;
    let activeSubmodule;
    const cellToFill = {
      content: "  ",
      styles: {
        lineColor: [52, 172, 215],
      },
    };
    if (m.submodules && m.submodules.length > 0) {
      activeSubmodule = m.submodules[0];
      activeSubmodule.next = 1;
    }
    m.subjects.forEach((c) => {
      if (m.submodules && m.submodules.length > 0) {
        if (activeSubmodule && activeSubmodule._id == c.submoduleID) {
          let submoduleHeader = [];
          submoduleHeader[7] = {
            content: activeSubmodule.label,
            colSpan: 2,
            styles: {
              halign: "center",
              valign: "middle",
              fillColor: "#ffffff", //"#40bbef",
              fontSize: tableSize + 1,
              cellPadding: 0.5,
              textColor: "#40bbef",
              lineColor: [52, 172, 215],
              overflow: "visible",
            },
          };
          submoduleHeader[4] = cellToDelete;
          if (style == 1) submoduleHeader[3] = cellToDelete;
          submoduleHeader[2] = cellToDelete;
          submoduleHeader[1] = {
            content: " ",
            style: {
              lineColor: [255, 255, 255],
            },
          };
          submoduleHeader[0] = {
            content: " ",
            style: {
              lineColor: [255, 255, 255],
            },
          };
          array.push(submoduleHeader);
          if (activeSubmodule && activeSubmodule.next < m.submodules.length) {
            const nextSM = activeSubmodule.next;
            activeSubmodule = m.submodules[nextSM];
            activeSubmodule.next = nextSM + 1;
          } else {
            activeSubmodule = undefined;
          }
        }
      }
      let subFormattedName = c.name;
      if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
        let indexOfFirst = c.name.indexOf(" و ");
        subFormattedName =
          c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
      }
      const lines = subFormattedName.split("\n");
      // Get the maximum length
      const maxLength = Math.max(...lines.map((line) => line.length));
      let subjectFontSize = maxLength >= 23 ? tableSize : tableSize + 1;

      arr[m.submodules ? 7 : 6] = {
        content: subFormattedName,
        styles: {
          halign: "right",
          valign: "middle",
          fillColor: "#40bbef",
          fontSize: subjectFontSize,
          cellPadding: {
            top: 0.8,
            bottom: 0.8,
            right: 2,
            left: 1,
          },
          textColor: "#ffffff",
          lineColor: [229, 229, 229],
        },
      };
      arr[m.submodules ? 6 : 5] = {
        content: c.mark ?? "--",
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
          lineColor: [52, 172, 215],
        },
      };

      arr[4] = cellToDelete;
      if (style == 1) arr[3] = cellToDelete;
      arr[2] = cellToDelete;

      //best mark
      arr[1] = {
        content: c.max,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
          lineColor: [52, 172, 215],
          overflow: "visible", // Ensures text does not wrap or cut off
        },
      };

      //lowest mark
      arr[0] = {
        content: c.min,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: 10,
          lineColor: [52, 172, 215],
          overflow: "visible", // Ensures text does not wrap or cut off
        },
      };

      array.push(arr);
      arr = [];
    });

    //end shifting

    array[0][2] = {
      content: "",
      rowSpan: array.length,
      styles: {
        lineColor: [52, 172, 215],
      },
    };
    const noSubmodulesFound =
      !m.submodules ||
      m.submodules.every((submodule) => submodule.NbSubjects === 0);
    if (noSubmodulesFound) {
      array[0][style == 1 ? 3 : 4] = {
        content: m.appreciation.trim(),
        rowSpan: array.length,
        styles: {
          halign: "center",
          valign: "middle",
          fontSize: m.appreciation.length > 15 ? 10 : 12,
          lineColor: [52, 172, 215],
        },
      };
    } else {
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        let rowNumber = subModule.NbSubjects + 1; // +1 for the submodule name
        if (array[appIndex] === undefined) array[appIndex] = [];
        array[appIndex][3] = {
          content: subModule.appreciation ? subModule.appreciation.trim() : "",
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            lineColor: [52, 172, 215],
          },
        };
        appIndex += rowNumber;
      });
    }

    if (style == 1)
      array[0][4] = {
        content: m.moyenne,
        rowSpan: array.length,
        styles: {
          halign: "center",
          fontSize: 12,
          valign: style ? "center" : "top",
          lineColor: [52, 172, 215],
        },
      };

    if (m.submodules) {
      /******moyenne sub module */
      let appIndex = 0;
      m.submodules.forEach((subModule) => {
        let rowNumber = subModule.NbSubjects + 1; // +1 for the submodule name
        if (array[appIndex] === undefined) array[appIndex] = [];
        array[appIndex][style == 1 ? 5 : 4] = {
          content: isNumber(subModule.moyenne)
            ? Number(subModule.moyenne).toFixed(2)
            : "",
          rowSpan: rowNumber,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            lineColor: [52, 172, 215],
          },
        };
        appIndex += rowNumber;
      });
    }

    for (let row = 0; row < array.length; row++) {
      array[row] = array[row].filter((cell) => cell !== cellToDelete);
    }
    const bodyStyles =
      totatPts > totalPtsTreshold
        ? {
            minCellHeight: 5,
            cellPadding: {
              vertical: 1.5,
              horizontal: 1,
            },
          }
        : {};
    doc.autoTable({
      startY: 64 + topMargin,
      theme: "grid",
      styles: {
        font: "Amiri",
        halign: "center",
        fontStyle: "Bold",
      },
      bodyStyles: bodyStyles,
      cellPadding: 0,
      body: array,
      margin: { left: style == 1 ? 65 : 80, bottom: 0 },
      didDrawPage: function (data) {
        // Reseting top margin. The change will be reflected only after print the first page.
        data.settings.margin.top = 10;
        bulletinfOnTwoPages = bulletinfOnTwoPages =
          doc.internal.getNumberOfPages() > studentCount;
      },
      columnStyles:
        style == 1
          ? m.submodules
            ? {
                0: { cellWidth: 11 },
                1: { cellWidth: 11 },
                2: { cellWidth: 1 },
                3: { cellWidth: 36 },
                4: { cellWidth: 15 },
                5: { cellWidth: 14 },
                6: { cellWidth: 14 },
                7: { cellWidth: 37 },
              }
            : {
                0: { cellWidth: 15 },
                1: { cellWidth: 15 },
                2: { cellWidth: 1 },
                3: { cellWidth: 38 },
                4: { cellWidth: 15 },
                5: { cellWidth: 17 },
                6: { cellWidth: 38 },
              }
          : m.submodules
          ? {
              0: { cellWidth: 15 },
              1: { cellWidth: 15 },
              2: { cellWidth: 1 },
              3: { cellWidth: 38 },
              4: { cellWidth: 12 },
              5: { cellWidth: 16 },
              6: { cellWidth: 27 },
            }
          : {
              0: { cellWidth: 15 },
              1: { cellWidth: 15 },
              2: { cellWidth: 1 },
              3: { cellWidth: 46 },
              4: { cellWidth: 17 },
              5: { cellWidth: 30 },
            },
    });

    if (style == 0)
      drawModuleFooter(doc, ctx, doc.lastAutoTable.finalY + 1, m.moyenne);

    topMargin = doc.lastAutoTable.finalY - 61.4 + (style == 0 ? 11 : 0);
  });

  let cardStartY = 0;
  // Add extra space if birth date is on new line
  const extraSpace = currentY > yCoord ? 8 : 0;
  switch (selectedLevel) {
    case 1:
      cardStartY = -15 + extraSpace;
      break;
    case 2:
      cardStartY = -15 + extraSpace;
      break;
    case 3:
      cardStartY = -22 + extraSpace;
      break;
    case 4:
      cardStartY = -22 + extraSpace;
      break;
    case 5:
      cardStartY = -22 + extraSpace;
      break;
    case 6:
      cardStartY = -22 + extraSpace;
      break;
    default:
      cardStartY = extraSpace;
      break;
  }

  drawTrimesterMoyenneAndCards(
    doc,
    ctx,
    cardStartY,
    student.moyenne,
    bulletinData.maxMoyenne,
    bulletinData.minMoyenne,
    style,
    stampImage,
    student.adminAppreciation,
    config
  );
};

const getAffichageBulletin = async (level) => {
  let affichageBulletin = {
    numberStudentInsc: false,
    identUnique: false,
    type: "peda",
  };
  await apiClient
    .post("/affichageBulletin/filter", {
      query: {
        level: level,
        type: "peda",
      },
    })
    .then((res) => {
      if (res.data[0]) {
        affichageBulletin = res.data[0];
        console.log(affichageBulletin);
      }
    })
    .catch((e) => {
      console.error(e);
    });
  return affichageBulletin;
};
//check missing marks and show modal
const prePrintBulletin = async (template, config, this_) => {
  const { selectedStudent, selectedTrimester } = config;
  let bulletinData = {};

  // Fetching bulletin data
  try {
    let { data } = await apiClient.get(
      `/bulletin/v2/bt/MP/${selectedStudent}/${selectedTrimester}`
    );
    if (
      config.settings.activeBuilding == "mondialPrimaire" ||
      config.settings.activeBuilding == "abcSchoolMahdia"
    ) {
      const res = await apiClient.get(
        `/bulletin/v2/bt/MS/${selectedStudent}/${selectedTrimester}`
      );
      const specData = res.data;
      if (specData) {
        const { moyenne, minMoyenne, maxMoyenne } = specData;
        data = updateWithSpecDataOne(data, moyenne, minMoyenne, maxMoyenne);
      }
    }
    bulletinData = data;
  } catch (error) {
    console.error("Failed to fetch bulletin data:", error);
    this_.printLoading = false;
    return;
  }

  // Preprint checks
  let missingMarks = [];
  bulletinData.modules.forEach((m) => {
    m.subjects.forEach((c) => {
      if (c.mark === "--" || c.mark == null) missingMarks.push(c.name);
    });
  });

  // If there are missing marks, show a confirmation modal
  if (missingMarks.length !== 0) {
    const confirmed = await new Promise((resolve) => {
      Modal.confirm({
        title: this_.$t("bulletin.confirmPrintMatieres", {
          count: missingMarks.length,
        }),
        content: (h) => {
          const list = missingMarks.map((s) => h("li", s));
          return h("ul", list);
        },
        okText: this_.$t("bulletin.generateAnyway"),
        okType: "danger",
        cancelText: this_.$t("action.annuler"),
        async onOk() {
          try {
            await printBulletin(template, config, false, bulletinData);
            resolve(true); // User confirmed
          } catch (error) {
            console.error("Failed to print bulletin:", error);
            resolve(false);
          }
        },
        onCancel() {
          this_.printLoading = false;
          resolve(false); // User cancelled
        },
      });
    });

    if (!confirmed) {
      return; // Exit if not confirmed
    }
  } else {
    // Directly print if there are no missing marks
    try {
      await printBulletin(template, config, false, bulletinData);
    } catch (error) {
      console.error("Failed to print bulletin:", error);
    }
  }
};
const prePrintAllBulletin = async (template, config, this_, missingStudent) => {
  if (missingStudent && missingStudent.length !== 0) {
    const confirmed = await new Promise((resolve) => {
      Modal.confirm({
        title: this_.$t("bulletin.confirmPrintEleves", {
          count: missingStudent.length,
        }),
        content: (h) => {
          const list = missingStudent.map((s) => h("li", s));
          return h("ul", list);
        },
        okText: this_.$t("bulletin.generateAnyway"),
        okType: "danger",
        cancelText: this_.$t("action.annuler"),
        async onOk() {
          try {
            await printAllBulletin(template, config);
            resolve(true); // User confirmed
          } catch (error) {
            console.error("Error in printAllBulletin:", error);
            resolve(false);
          }
        },
        onCancel() {
          this_.printLoading = false;
          resolve(false); // User cancelled
        },
      });
    });

    if (!confirmed) {
      return; // Exit if user cancelled
    }
  } else {
    // Directly print if there are no missing students
    try {
      await printAllBulletin(template, config);
    } catch (error) {
      console.error("Error in printAllBulletin:", error);
    }
  }
};
const printBulletin = async (
  template,
  config,
  preview = false,
  bulletinData
) => {
  try {
    const {
      selectedLevel,
      schoolDetails,
      selectedStudent,
      settings,
      selectedTrimester,
    } = config;
    studentCount = 1;
    const style = selectedLevel > 2 ? 1 : 0;
    const affichageBulletin = await getAffichageBulletin(selectedLevel);

    //////end preprint
    const record = bulletinData;

    const doc = new JsPDF();
    const ctx = doc.context2d;
    //stamp
    let stamp = settings.base_url + "/" + schoolDetails.stamp;
    const stampImage = new Image(30);
    if (stamp) stampImage.src = stamp;
    if (stamp) {
      try {
        await waitLoadingImage(stampImage);
        //doc.addImage(stampImage, "PNG", 10, 180, 40, 40);
      } catch (e) {}
    }

    drawBulletin(
      doc,
      ctx,
      bulletinData,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
    if (preview) return doc;
    else doc.save("Bulletin " + record.fullName + ".pdf");
  } catch (error) {
    console.log(error);
    //printLoading = false;
  }
};

const printAllBulletin = async (template, config) => {
  const {
    selectedLevel,
    schoolDetails,
    selectedTrimester,
    settings,
    selectedClasse,
    selectedClassName,
  } = config;
  studentCount = 1;
  const doc = new JsPDF();
  const ctx = doc.context2d;
  let bulletinData;
  let firstPage = true;

  const affichageBulletin = await getAffichageBulletin(selectedLevel);
  await apiClient
    .get("/bulletin/v2/bt/MP/all/" + selectedClasse + "/" + selectedTrimester)
    .then(({ data }) => {
      bulletinData = data;
    });
  if (
    config.settings.activeBuilding == "mondialPrimaire" ||
    config.settings.activeBuilding == "abcSchoolMahdia"
  ) {
    const res = await apiClient.get(
      "/bulletin/v2/bt/MS/all/" + selectedClasse + "/" + selectedTrimester
    );
    const specData = res.data;
    if (specData) {
      bulletinData = updateWithSpecDataMany(bulletinData, specData);
    }
  }
  //stamp
  let stamp = settings.base_url + "/" + schoolDetails.stamp;
  const stampImage = new Image(30);
  if (stamp) stampImage.src = stamp;
  if (stamp) {
    try {
      await waitLoadingImage(stampImage);
      //doc.addImage(stampImage, "PNG", 10, 180, 40, 40);
    } catch (e) {}
  }
  //end stamp

  const className = selectedClassName;

  bulletinData.students.forEach((student) => {
    if (!firstPage) {
      doc.addPage();
      studentCount++;
    }
    drawBulletin(
      doc,
      ctx,
      student,
      bulletinData,
      affichageBulletin,
      stampImage,
      config
    );
    firstPage = false;
  });
  doc.save(`Bulletin ${className}.pdf`);
};
//---------------------------------------------------
/*
const printAllBulletin = async function (useTemplateSchool, config) {
  const {
    selectedLevel,
    schoolDetails,
    type,
    selectedTrimester,
    settings,
    selectedClasse,
    selectedClassName,
    bulletinType,
  } = config;
  const doc = new JsPDF();
  const ctx = doc.context2d;
  let bulletinData;
  let firstPage = true;
  let affichageBulletin;
  await apiClient
    .post("/affichageBulletin/filter", {
      query: {
        level: selectedLevel,
        type: type,
      },
    })
    .then((res) => {
      if (res.data[0]) {
        affichageBulletin = res.data[0];
      } else {
        affichageBulletin =
          type == "peda"
            ? {
                numberStudentInsc: false,
                identUnique: false,
                type: "peda",
              }
            : {
                verySpecific: false,
                pedagogic: true,
                personalized: false,
                logo: true,
                verySpecificName: "معدل",
              };
      }
    })
    .catch((e) => {
      $message.error($t("error.erreur"));
    });
  // getAllStudentBulletinVerySpecific
  await apiClient
    .get(
      "/bulletin/v2/mark/all/" +
        selectedClasse +
        "/" +
        selectedTrimester +
        "/" +
        bulletinType
    )
    .then(({ data }) => {
      bulletinData = data;
    });

  //stamp
  let stamp = settings.base_url + "/" + schoolDetails.stamp;
  const stampImage = new Image(30, 30);
  if (stamp) stampImage.src = stamp;
  if (stamp) {
    try {
      await waitLoadingImage(stampImage);
    } catch (e) {}
  }
  //end stamp

  await chooseBulletinDate();
  if (
    (!useTemplateSchool && affichageBulletin.logo) ||
    useTemplateSchool == "MP"
  ) {
    let arrayOfImages = settings.image;
    for (var i = 0; i < arrayOfImages.length; i++) {
      if (settings.activeBuilding === arrayOfImages[i].db) {
        var imgData = new Image();
        imgData.src = arrayOfImages[i].logo;
      }
    }
  }
  const pdf_width = doc.internal.pageSize.getWidth();
  bulletinData.students.forEach((student) => {
    if (!firstPage) {
      doc.addPage();
    }
    if (!useTemplateSchool && affichageBulletin.logo) {
      doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 6, 14, 14);
    }
    if (useTemplateSchool == "MP") {
      doc.addImage(imgData, "JPEG", pdf_width / 2 - 10, 0, 20, 20);
      drawBulletinMondial(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage
      );
    } else if (useTemplateSchool == "MS")
      drawBulletinMalekEsaghir(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage
      );
    else
      drawBulletin(
        doc,
        ctx,
        student,
        bulletinData,
        affichageBulletin,
        stampImage,
        config
      );
    firstPage = false;
  });

  const className = selectedClassName;
  doc.save(`Bulletin ${className}.pdf`);
};
const printPVAnnuel = async function (config) {
  const { settings } = config;
  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const className = selectedClassName;

  doc.text("PV Annuel Spécifique " + className, pdf_width / 2, 20, {
    align: "center",
  });

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);

  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/verySpecificBulletin/final/all/" + selectedClasse)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
      { content: "#", styles: { fontStyle: "Bold" } },
    ],
  ];

  let id = 1;
  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.fullName);
    studentData.push(id++);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 10,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel ${className}.pdf`);
};

const printPVAnnuelByLevel = async function () {
  const cellToDelete = {};

  const doc = new JsPDF({
    orientation: "m",
    unit: "mm",
    format: "a3",
  });

  doc.setFont("Amiri");

  const pdf_width = doc.internal.pageSize.getWidth();

  let arrayOfImages = settings.image;
  for (var i = 0; i < arrayOfImages.length; i++) {
    if (settings.activeBuilding === arrayOfImages[i].db) {
      var imgData = new Image();
      imgData.src = arrayOfImages[i].logo;
    }
  }

  doc.addImage(imgData, "JPEG", 10, 8, 20, 20);

  const levels = {
    "-2": "Préscolaire",
    "-1": "تحضيري",
    1: "سنة أولى",
    2: "سنة ثانية",
    3: "سنة ثالثة",
    4: "سنة رابعة",
    5: "سنة خامسة",
    6: "سنة سادسة",
    7: "سنة سابعة",
    8: "سنة ثامنة",
    9: "سنة تاسعة",
    10: "أولى ثانوي",
    11: "ثانية ثانوي",
    12: "ثالثة ثانوي",
    13: "رابعة ثاتوي",
  };

  doc.text(
    "PV Annuel Spécifique - " + levels[selectedLevel],
    pdf_width / 2,
    20,
    {
      align: "center",
    }
  );

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  doc.setFontSize(9);
  doc.text(new Date().toLocaleDateString("fr-FR", options), pdf_width - 17, 8, {
    align: "right",
  });

  let bulletinData;

  await apiClient
    .get("/verySpecificBulletin/pv/final/" + selectedLevel)
    .then((res) => (bulletinData = res.data));

  let array = [
    [
      { content: "الرتبة", styles: { fontStyle: "Bold" } },
      { content: "معدل العام", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثالث", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الثاني", styles: { fontStyle: "Bold" } },
      { content: "معدل الثلاثي الأول", styles: { fontStyle: "Bold" } },
      { content: "القسم", styles: { fontStyle: "Bold" } },
      { content: "الأسم", styles: { fontStyle: "Bold" } },
    ],
  ];

  bulletinData.students.forEach((student) => {
    const studentData = [];
    studentData.push(student.rank);
    studentData.push(student.finalMoyenne);
    studentData.push(student.moyenneThree);
    studentData.push(student.moyenneTwo);
    studentData.push(student.moyenneOne);
    studentData.push(student.classroomName);
    studentData.push(student.fullName);
    array.push(studentData);
  });

  // fix table
  for (let row = 0; row < array.length; row++) {
    array[row] = array[row].filter((cell) => cell !== cellToDelete);
  }

  doc.autoTable({
    startY: 35,
    body: array,
    theme: "striped",
    styles: {},
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [73, 138, 159],
      halign: "center",
      fontSize: 9,
      font: "Amiri",
      // cellPadding: 1.2,
    },
    columnStyles: {
      0: { cellWidth: 20 },
    },
    rowPageBreak: "avoid",
  });

  //page numbering
  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(9); //Optional

  doc.setTextColor(0, 0, 0);

  for (let j = 1; j < pages + 1; j++) {
    let horizontalPos = pageWidth / 2;
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }

  //end page numbering

  doc.save(`PV Annuel Spécifique ${levels[selectedLevel]}.pdf`);
};
*/
export default {
  // drawBulletinMalekEsaghir,
  //drawBulletinMondial,
  printBulletin,
  prePrintBulletin,
  printAllBulletin,
  prePrintAllBulletin,
  //printPVAnnuel,
  //  printPVAnnuelByLevel,
};
